<template>
    <div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" ref="modal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    </div>
                    <div class="modal-body">
                        <div style="display: flex; flex-direction: column; width: 100%;height: 50%;">
                                <form v-if="userId == null"
                                    style="display: flex; flex-direction: column; width: 100%;height: 50%;">
                                    <label for="username">اسم المستخدم:</label>
                                    <input id="username" type="input" style="margin-right: 10px;" v-model="username"
                                        required />
                                    <label for="password">كلمة السر:</label>
                                    <input id="password" type="password" style="margin-right: 10px;" v-model="password"
                                        required />
                                    <label for="con-password">تأكيد كلمة السر:</label>
                                    <input id="con-password" type="password" style="margin-right: 10px;"
                                        v-model="confirmPassword" required />
                                </form>
                                <UploadImage @save="saveImage" :url="image" />
                            <label for="arabicName">الاسم العربي:</label>
                            <input id="arabicName" type="input" style="margin-right: 10px;" v-model="authorNameAr"
                                required />
                            <label for="englishName">الاسم الانجليزي:</label>
                            <input id="englishName" type="input" style="margin-right: 10px;" v-model="authorNameEn"
                                required />
                            <label for="privileges">الصلاحيات:</label>
                            <select id="privileges" v-model="privilege">
                                <option v-for="option in options" :value="option" :key="option">
                                    {{ option == "ROLE_USER" ? "محدود" : "مشرف" }}
                                </option>
                            </select>
                            <SwitchComponent :label="'نشط'" :initial-value="enabled" @toggle="toggle" />
                            <textarea class="form-control" v-model="authorDesc" placeholder="وصف الكاتب..."></textarea>
                            <br>
                            <textarea class="form-control" v-model="authorDescEn" placeholder="Description..."></textarea>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <p v-if="errorMsg" class="error">{{ errorMsg }}</p> -->
                        <button type="button" class="btn btn-secondary" 
                        @click="closeModal">إلغاء</button>
                        <button type="button" class="btn btn-primary" @click="save">حفظ</button>
                    </div>
                </div>
            </div>
        </div>
        <ErrorDialog :msg="errorMsg" />
    </div>
</template>
  
<script setup>
import { onMounted, nextTick, ref, watch } from "vue";
import { ApiClient, AuthenticationControllerApi, UsersEntity, AuthorEntity } from "../api/index.js"
import SwitchComponent from "./SwitchComponent.vue";
import UploadImage from "./UploadImage.vue";
import { uploadFileToFirebase} from "../firebase/Utils.js";
import { getAuth, createUserWithEmailAndPassword  } from "firebase/auth";
import ErrorDialog from './ErrorDialog.vue';

const props = defineProps(["userToEdit"]);
const emit = defineEmits(["save"]);


const apiClient = new ApiClient();

const usersApi = new AuthenticationControllerApi(apiClient);

const options = ref([]);

const title = ref("مستخدم جديد");

//
const userId = ref(null);
const authorId = ref(null);
//

const username = ref(null);
const password = ref("");
const confirmPassword = ref("");
const errorMsg = ref(null);
const enabled = ref(true);
const image = ref(null);
const authorDesc=ref("");
const authorDescEn=ref("");



const authorNameAr = ref('');
const authorNameEn = ref('');
const privilege = ref('ROLE_USER');

watch(
    () => props.userToEdit,
    (newUser) => {

        if (newUser) {
            console.log(newUser);
            title.value = "تعديل مستخدم";
            userId.value = newUser.id;
            authorNameAr.value = newUser.author.displayName;
            authorNameEn.value = newUser.author.displayNameEn;
            privilege.value = newUser.privileges;
            enabled.value = newUser.enabled;
            authorId.value = newUser.author.id;
            image.value=newUser.author.imgUrl;
            authorDesc.value=newUser.author.description;
            authorDescEn.value=newUser.author.descriptionEn;
            console.log( image.value);
        } else {
            title.value = "مستخدم جديد";
            userId.value = null;
            authorNameAr.value = "";
            authorNameEn.value = "";
            privilege.value = "";
            enabled.value = true;
            authorId.value = null;
            image.value = null;
            authorDesc.value="";
            authorDescEn.value="";
        }
    }
);

async function saveImage(imageData) {
    const randomName = Math.random().toString(36).substring(2, 15);
    image.value = await uploadFileToFirebase(imageData, "images/users", `${randomName}.jpg`);
}

onMounted(() => {
    nextTick(() => {
        const modalEls = document.querySelectorAll('.modal');
        modalEls.forEach((el) => {
            new window.bootstrap.Modal(el);
        });
        options.value = ["ROLE_ADMIN", "ROLE_USER"]
    });
});

function toggle(state) {
    enabled.value = state;
}


// function save() {
//     try {
//         var user = new UsersEntity();
//         user.author = new AuthorEntity();
//         if (!userId.value) {
//             if (!username.value) {
//                 errorMsg.value = 'يرجئ تعبئة حقل اسم المستخدم';
//                 return;
//             }
//             if (!password.value || !confirmPassword.value) {
//                 errorMsg.value = 'يرجئ تعبئة حقول كلمة السر';
//                 return;
//             }

//             if (password.value !== confirmPassword.value) {
//                 errorMsg.value = "كلمات السر غير متطابقة";
//                 return;
//             }
//         } else {
//             user.id = userId.value;
//             user.author.id = authorId.value;
//         }



//         const userAr = authorNameAr.value;
//         const userEn = authorNameEn.value;


//         if (!userAr) {
//             errorMsg.value = "يرجئ تعبئة خانة الاسم العربي";
//             return;
//         }
//         if (!userEn) {
//             errorMsg.value = "يرجئ تعبئة خانة الاسم الانجليزي";
//             return;
//         }      
//          if (!image.value) {
//             errorMsg.value = "يرجئ إختيار صورة للمستخدم";
//             return;
//         }

//         user.username = username.value;
//         user.password = password.value;
//         user.author = {
//             id: authorId.value,
//             displayName: userAr || (() => { throw new Error('الاسم العربي لا يمكن أن يكون فارغ') })(),
//             displayNameEn: userEn || (() => { throw new Error('الاسم الانجليزي لا يمكن أن يكون فارغ') })(),
//             imgUrl:image.value,
//             description:authorDesc,
//             descriptionEn: authorDescEn
//         }
//         // user.author = user.getAuthor;
//         user.privileges = privilege.value;

//         user.enabled = enabled.value;

//         errorMsg.value = "";

//         if (userId.value)
//             usersApi.updateUser(user, localStorage.getItem("token"), (error, data, response) => {
//                 if (!error)
//                     location.reload();
//                 else {
//                     errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
//                 }
//             });
//         else
//             usersApi.addUser(user, localStorage.getItem("token"), async (error, data, response)  => {
//                 if (error == null){
//                 const auth = getAuth(); 
//              createUserWithEmailAndPassword(auth,`${user.username}@yafeapedia.com`, user.password).then(()=>{
//                 //todo
//              }).catch((error)=>{
//                 errorMessage.value = error.message || 'خطأ غير متوقع!';
//              }).finally(()=>{ 
//                 location.reload()
//              });
//                 }
//                 else {
//                     errorMessage.value = error.message || 'حصل خطأ ما خلال الحفظ!';
//                 }

//             });
//     } catch (error) {
//         errorMessage.value = error.message || 'خطأ غير متوقع!';
//     }

// }

function closeModal() {
    document.location.reload();
}

function save() {
    try {
        errorMsg.value = ''; // Reset error message
        var user = new UsersEntity();
        user.author = new AuthorEntity();
        if (!userId.value) {
            if (!username.value) {
                errorMsg.value = 'يرجئ تعبئة حقل اسم المستخدم';
                return;
            }
            if (!password.value || !confirmPassword.value) {
                errorMsg.value = 'يرجئ تعبئة حقول كلمة السر';
                return;
            }

            if (password.value !== confirmPassword.value) {
                errorMsg.value = "كلمات السر غير متطابقة";
                return;
            }
        } else {
            user.id = userId.value;
            user.author.id = authorId.value;
        }



        const userAr = authorNameAr.value;
        const userEn = authorNameEn.value;


        if (!userAr) {
            errorMsg.value = "يرجئ تعبئة خانة الاسم العربي";
            return;
        }
        if (!userEn) {
            errorMsg.value = "يرجئ تعبئة خانة الاسم الانجليزي";
            return;
        }      
         if (!image.value) {
            errorMsg.value = "يرجئ إختيار صورة للمستخدم";
            return;
        }

        user.username = username.value;
        user.password = password.value;
        user.author = {
            id: authorId.value,
            displayName: userAr || (() => { throw new Error('الاسم العربي لا يمكن أن يكون فارغ') })(),
            displayNameEn: userEn || (() => { throw new Error('الاسم الانجليزي لا يمكن أن يكون فارغ') })(),
            imgUrl:image.value,
            description:authorDesc.value,
            descriptionEn: authorDescEn.value
        }
        // user.author = user.getAuthor;
        user.privileges = privilege.value;

        user.enabled = enabled.value;

        errorMsg.value = "";

        if (userId.value)
            usersApi.updateUser(user, localStorage.getItem("token"), (error, data, response) => {
                if (!error)
                    location.reload();
                else {
                    errorMsg.value = error.message || 'حصل خطأ ما خلال الحفظ!';
                }
            });
        else
            usersApi.addUser(user, localStorage.getItem("token"), async (error, data, response)  => {
                if (error == null){
                const auth = getAuth(); 
             createUserWithEmailAndPassword(auth,`${user.username}@yafeapedia.com`, user.password).then(()=>{
                //todo
             }).catch((error)=>{
                errorMsg.value = error.message || 'حصل خطأ ما خلال الحفظ!';
             }).finally(()=>{ 
                location.reload()
             });
                }
                else {
                    errorMsg.value = error.message || 'حصل خطأ ما خلال الحفظ!';
                }

            });
    } catch (error) {
        errorMsg.value = error.message || 'خطأ غير متوقع!';
    }
}

</script>

<style scoped>
.modal-content {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.modal-header {
  background-color: var(--primary-color);
  color: var(--text-light);
  padding: 1rem 1.5rem;
}

.modal-title {
  font-weight: bold;
}

.modal-body {
  background-color: var(--background-light);
  padding: 1.5rem;
}

.modal-footer {
  background-color: var(--background-light);
  border-top: 1px solid var(--border-color);
  padding: 1rem 1.5rem;
}

.btn {
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-light);
}

.btn-primary:hover:not(:disabled) {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: var(--text-light);
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  transition: border-color 0.2s ease-in-out;
  margin-bottom: 1rem;
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(89, 3, 5, 0.25);
}

.error {
  color: red;
  margin-bottom: 1rem;
}
</style>
