<template>
  <div :class="['sidebar-wrapper d-flex flex-column', { 'toggled': isCollapsed }]" id="sidebar-wrapper">
    <div class="logo py-4 px-3">
      <img :class="{'img-fluid': !isCollapsed, 'img-collapsed': isCollapsed}" src="../assets/logo-small.png" alt="Logo" />
    </div>
    <div class="list-group list-group-flush flex-grow-1">
      <router-link to="/home/dashboard" exact :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-home"></i> <span v-if="!isCollapsed">الرئيسية</span>
      </router-link>
      <router-link to="/home/categories" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-folder"></i> <span v-if="!isCollapsed">الأقسام</span>
      </router-link>
      <router-link to="/home/articles" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-newspaper"></i> <span v-if="!isCollapsed">المقالات</span>
      </router-link>
      <router-link to="/home/media" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-image"></i> <span v-if="!isCollapsed">الوسائط</span>
      </router-link>
      <router-link to="/home/users" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="toggleActive">
        <i class="fas fa-users"></i> <span v-if="!isCollapsed">المستخدمون</span>
      </router-link>
      <a href="#" :class="['list-group-item list-group-item-action',isCollapsed?'text-center':'']" @click="logout">
        <i class="fas fa-sign-out-alt"></i> <span v-if="!isCollapsed">تسجيل الخروج</span>
      </a>
    </div>
    <button @click="toggleCollapse" class="btn btn-toggle mt-auto">
      <i :class="['fas', isCollapsed ? 'fa-angle-double-left' : 'fa-angle-double-right']"></i>
    </button>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import {signOut,getAuth} from 'firebase/auth'

const isCollapsed = inject('isCollapsed');

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};

const logout = () => {
  localStorage.clear();
  window.location.reload();
  signOut(getAuth());
};

const toggleActive = (event) => {
  const currentActive = document.querySelector('.list-group-item.active');
  if (currentActive) {
    currentActive.classList.remove('active');
  }
  event.currentTarget.classList.add('active');
};
</script>

<style scoped>
.sidebar-wrapper {
  background-color: var(--background-light);
  box-shadow: -0.5rem 0 1rem rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: auto;
  width: 250px;
}

.sidebar-wrapper.toggled {
  width: 80px;
}

.logo img {
  transition: opacity 0.3s ease-in-out, width 0.3s ease;
}

.toggled .logo img {
  opacity: 0.7;
  width: 50px;
}

.img-collapsed {
  width: 50px;
  transition: width 0.3s ease;
}

.list-group-item {
  background-color: transparent;
  color: var(--text-dark);
  border: none;
  padding: 0.75rem 1.25rem;
  transition: all 0.2s ease-in-out;
}

.list-group-item:hover {
  background-color: var(--primary-light);
  color: var(--text-light);
}

.list-group-item.active {
  background-color: var(--primary-color);
  color: var(--text-light);
  font-weight: bold;
}

.btn-toggle {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  border-radius: 0;
  padding: 0.75rem;
  transition: all 0.2s ease-in-out;
}

.btn-toggle:hover {
  background-color: var(--primary-light);
}
</style>
