<template>
    <div class="media-wrapper">
        <div class="container-fluid media-container">
            <h2 class="text-center mb-4">الوسائط</h2>
            <div class="row mb-4">
                <div class="col-12 d-flex justify-content-between align-items-center flex-wrap">
                    <div class="btn-group">
                        <button class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-plus"></i> جديد
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#" @click="openModal(0)">صورة</a>
                            <a class="dropdown-item" href="#" @click="openModal(1)">صوت</a>
                        </div>
                    </div>
                    <SearchComponent @submit:search="startSearch"/>
                </div>
            </div>
            <AlbumModal :albumToEdit="selectedAlbum" :mediaType="mediaType"/>

            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>العنوان</th>
                            <th>القسم</th>
                            <th>الناشر</th>
                            <th>اللغة</th>
                            <th>تاريخ الإضافة</th>
                            <th>آخر تعديل</th>
                            <th>آخر تعديل بواسطة</th>
                            <th>عمليات</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="album in albums" :key="album.id">
                            <td>{{ album.title }}</td>
                            <td>{{ album.category.nameAr }}</td>
                            <td>{{ album.author.displayName }}</td>
                            <td>{{ album.lang == 1 ? "عربي" : "أنجليزي" }}</td>
                            <td>{{ formatDateTime(album.addedAt) }}</td>
                            <td>{{ album.lastEdit?formatDateTime(album.lastEdit):"لم يسبق تعديله" }}</td>
                            <td>{{ album.lastEditBy?album.lastEditBy.displayName:"" }}</td>
                            <td>
                                <div class="btn-group" role="group">
                                    <a class="btn btn-action" :href="'https://staging.yafeapedia.com/media/' + album.id" target="_blank">
                                        عرض
                                    </a>
                                    <button class="btn btn-action edit-button" @click="editAlbum(album)" data-toggle="modal"
                                        data-target="#albumModal">
                                        تعديل
                                    </button>
                                    <DeleteButtonComponent :id="album.id" @delete="deleteAlbum"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                            @page-changed="handlePageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { MediaControllerApi, ApiClient,SearchControllerApi } from "../api/index.js";
import { ref } from 'vue';
import AlbumModal from "@/components/AlbumModal.vue";
import PaginationComponent from '@/components/PaginationComponent.vue';
import SearchComponent from "@/components/SearchComponent.vue";
import DeleteButtonComponent from '@/components/DeleteButtonComponent.vue';

const apiClient = new ApiClient();
const mediaApi = new MediaControllerApi(apiClient);
const searchApi =new SearchControllerApi(apiClient);

const albums = ref([]);

const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedAlbum = ref({});

const mediaType=ref(0);

const fetchAlbums = () => {
    if(!query.value)
    mediaApi.getAllAlbums(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
        parseResult(data);
    });
    else
    searchApi.searchMedia(currentPage.value,pageSize.value,query.value,1,(error,data,response)=>{
        parseResult(data);
        console.log(data);
    });
};

function parseResult(data){
    albums.value = data.content;
    currentPage.value = data.pageable.pageNumber;
    pageSize.value = data.pageable.pageSize;
    totalElements.value = data.totalElements;
}

const editAlbum = (album) => {
    selectedAlbum.value = album;
}

const deleteAlbum = (id) => {
    mediaApi.deleteMedia(id, localStorage.getItem("token"), (error, data, response) => {
        if (data == true)
            location.reload();
    });
}

const formatDateTime = (dateObj) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ar-US', options).format(dateObj);
}

const openModal = (mt) => {
    mediaType.value=mt;
  selectedAlbum.value = null;
  $('#albumModal').modal('show');

};

const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchAlbums();
};
const startSearch = (_) => {
    currentPage.value=0;
    query.value=_;
    fetchAlbums();
};

fetchAlbums();
</script>

<style scoped>
.media-wrapper {
  padding: 1rem;
  min-height: 100vh;
  background-color: var(--background-light);
}

.media-container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

h2 {
    color: var(--primary-dark);
    font-weight: bold;
    margin-bottom: 2rem;
}

.table {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
}

.table thead {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.table th, .table td {
    vertical-align: middle;
}

.table tbody tr:hover {
    background-color: var(--background-light);
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .container-fluid {
        padding: 1rem;
    }

    .table-responsive {
        border: 0;
    }

    .table thead {
        display: none;
    }

    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 1rem;
        border: 1px solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.5rem;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
    }

    .btn-group {
        display: flex;
        flex-direction: row;
    }

    .btn-group .btn {
        margin-bottom: 0.5rem;
    }
}
</style>
