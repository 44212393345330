<template>
    <router-view />
</template>
<style>
:root {
  --primary-color: #590305 !important;
  --primary-light: #7a0407 !important;
  --primary-dark: #3b0203 !important;
  --text-light: #ffffff !important;
  --text-dark: #333333 !important;
  --background-light: #f8f9fa !important;
  --border-color: #ced4da !important;
}
#app {
  font-family: 'Cairo', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: #2c3e50 !important;
  direction: rtl !important;

}

.container {
  display: flex !important;
  padding-right: unset !important;
  padding-left: unset !important;
  margin-right: unset !important;
  margin-left: unset !important;
  flex-direction: row !important;
}

nav {
  padding: 30px !important;
}

nav a {
  font-weight: bold !important;
  color: #2c3e50 !important;
}


 .container {
   max-width: 100% !important;
} 
.btn:focus, .btn:active, .btn:hover {
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  color: var(--text-light) !important;
  border-radius: 8px !important; /* Ensure rounded corners on focus, active, and hover states */
}


.btn-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--text-light) !important;
    border-radius: 8px !important; /* Add rounded corners to specific button types */
}

.btn-primary:hover {
    background-color: var(--primary-light) !important;
    border-color: var(--primary-light) !important;
}

.btn-info {
    background-color: var(--primary-light) !important;
    border-color: var(--primary-dark) !important;
    color: var(--text-light) !important;
    border-radius: 8px !important; /* Add rounded corners to specific button types */
}

.btn-info:hover {
    background-color: var(--primary-dark) !important;
    border-color: var(--primary-light) !important;
}

.btn-warning {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-light) !important;
    color: var(--text-light) !important;
    margin-right: 0.5rem !important;
    border-radius: 8px !important; /* Add rounded corners to specific button types */
}

.btn-warning:hover {
    background-color: var(--primary-light) !important;
    border-color: var(--primary-light) !important;
}
.edit-button:focus, .edit-button:active, .edit-button:hover, .delete-button:focus, .delete-button:active, .delete-button:hover {
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  color: var(--text-light) !important;
  border-radius: 8px !important; /* Add rounded corners to edit and delete buttons */
}
.add-button {
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    cursor: pointer !important;
    margin-bottom: 10px !important;
    justify-self: right !important;
    border-radius: 8px; /* Add rounded corners to add button */
}

.add-button:hover {
    background-color: #4bc44f !important;
}

dropdown-item:focus, .dropdown-item:active, .dropdown-item:hover {
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  color: var(--text-light) !important;
}
.modal-xl {
    max-width: 99% !important;
    max-height: 99% !important;
}


select {
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
}

/* Customizing the dropdown menu */
select:focus {
  border-color: #007bff !important;
  outline: none !important;
}

select:hover {
  background-color: #f5f5f5 !important;
}

option:hover {
  background-color: #eee !important;
}
.edit-button,
.delete-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding: 5px 10px !important;
    border-radius: 8px !important;
    cursor: pointer !important;
    margin-right: 5px !important;
    border-radius: 8px;
}

.edit-button:hover,
.delete-button:hover {
    background-color: var(--primary-light) !important; /* Changed to use theme color */
}

.delete-button {
    background-color: #dc3545 !important;
}

.delete-button:hover {
    background-color: #b3001a !important;
}

.fa-pen {
    margin-right: 5px !important;
}

.error {
  color: red !important;
}
.btn-outline-primary {
    color: rgb(241, 88, 39) !important;
    border-color: rgb(241, 88, 39) !important;
    border-top-color: rgb(241, 88, 39) !important;
    border-right-color: rgb(241, 88, 39) !important;
    border-bottom-color: rgb(241, 88, 39) !important;
    border-left-color: rgb(241, 88, 39) !important;
}

.btn-outline-primary:hover {
    background-color: rgb(241, 88, 39) !important;
    border-color: rgb(241, 88, 39) !important;
    color: rgb(33, 37, 41) !important;
}
/* .table {
    width: 100%;
    border-collapse: collapse;
} */

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.table th,
.table td {
    padding: 8px !important;
    /* border: 1px solid #ccc; */
}

.table th {
    /* background-color: #f2f2f2; */
    border: 1px !important;
    border-collapse: collapse !important;
}

.btn-secondary{
  background-color: #590305 !important;
}
.btn-secondary:focus{
  background-color: #590305 !important;
}

.btn-secondary:hover{
  background-color: #590305 !important;
}
.fas{
  color: #590305 !important;
}
button .fas{
  color: unset !important;
}
.modal-static {
    pointer-events: none !important; /* Disables interactions */
}
.modal-static .modal-content {
    pointer-events: auto !important; /* Enables interactions inside the modal content */
}

.btn-action {
  background-color: var(--primary-color) !important;
  color: var(--text-light) !important;
  border: none !important;
  padding: 0.5rem 1rem !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  font-weight: bold !important;
  margin-right: 0.5rem !important;
  width: 100px !important; /* Set a fixed width for all buttons */
  text-align: center !important;
  
}

.btn-action:hover {
  background-color: var(--primary-light) !important;
}

.btn-action.edit-button {
  background-color: var(--primary-color) !important;
}

.btn-action.delete-button {
  background-color: #dc3545 !important;
}

.btn-action.delete-button:hover {
  background-color: #c82333 !important;
}

</style>
