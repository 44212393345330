<template>
  <div dir="rtl" class="home-container">
    <SideBar />
    <div class="main-content" :class="{ 'sidebar-collapsed': isCollapsed }">
      <UserStrip/>
      <router-view />
    </div>
  </div>
</template>

<script setup>
import SideBar from '@/components/SideBar.vue'
import UserStrip from '@/components/UserStrip.vue'
import { ref, provide } from 'vue'

const isCollapsed = ref(true)
provide('isCollapsed', isCollapsed)
</script>

<style scoped>
.home-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  margin-right: 250px; /* Default margin when sidebar is expanded */
  transition: margin-right 0.3s ease-in-out;
}

.main-content.sidebar-collapsed {
  margin-right: 80px; /* Adjust margin when sidebar is collapsed */
}

@media (max-width: 768px) {
  .main-content,
  .main-content.sidebar-collapsed {
    margin-right: 80px; /* Remove margin on small screens */
  }
}
</style>
