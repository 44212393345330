<template>
  <div class="dashboard" style=" width: 100%;">    
    <div class="content">
      <h3 style="text-align: right; width: 100%;">
        صور الصفحة الرئيسية:
      </h3>

      <div id="images-scroll" class="card-deck image-gallery" :style="{ overflowX: images.length > 3 ? 'scroll' : 'hidden', display: 'flex', flexWrap: 'nowrap' }">
        <label for="customFile" class="btn mt-2 d-flex justify-content-center align-items-center" style="margin-top: unset; margin-bottom: unset;">
          <i class="fas fa-upload"></i>
        </label>
        <div v-for="image in images" :key="image.id" class="card image-container" style="position: relative; margin-right: 7px; margin-left: 7px; flex: 0 0 auto;">
          <img :src="image.url" :alt="`Image ${image.id}`" class="card-img-top gallery-image"/>
          <button class="btn btn-danger delete-button" @click="deleteImage(image.id)" style="position: absolute; top: 10px; right: 10px;">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <input type="file" id="customFile" class="file-input d-none" @change="onImageFileChange" />
      
      <!-- Ads Section -->
      <br>
      <h3 style="text-align: right; width: 100%;"> 
        الإعلانات:
      </h3>
     
      <div class="ads-section card-deck image-gallery">
        <div v-for="ad in ads" :key="ad.id" class="ad-container">
          <img :src="ad.imgUrl" class="ad-preview" v-if="ad.imgUrl" :for="`adFile-${ad.id}`" @click="triggerFileInput(ad.id)"/>
          <div class="ad-controls">
            <input type="file" :id="`adFile-${ad.id}`" class="file-input d-none" @change="onAdFileChange($event, ad.id)" />
            <input type="text" v-model="ad.adUrl" placeholder="موقع الشركة المعلنة" class="url-input form-control form-control-sm" />
            <br>
            <button class="btn btn-success" @click="saveAd(ad.id)">حفظ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { uploadFileToFirebase } from '../firebase/Utils.js';
import { getDatabase, ref as dbRef, onValue, remove,  set,push,update} from 'firebase/database';
const images = ref([]);
const ads = ref([]); // Initialize with empty array for ads

const router = useRouter();
const isAuthenticated = ref(false);

const db = getDatabase();
const imagesRef = dbRef(db, 'home/images/');
const adsRef = dbRef(db, "home/ads/");

onMounted(() => {
  isAuthenticated.value = localStorage.getItem('isAuthenticated'); // Simplified check
  if (!isAuthenticated.value) {
    router.push('/');
  }
  onValue(imagesRef, (snapshot) => {
    images.value = [];
    snapshot.forEach((childSnapshot) => {
      images.value.push({ id: childSnapshot.key, ...childSnapshot.val() });
    });
  }); 
  onValue(adsRef, (snapshot) => {
    ads.value = [];
    snapshot.forEach((childSnapshot) => {
      ads.value.push({ id: childSnapshot.key, ...childSnapshot.val() });
    });
  });
});

const addImage = (url) => {
  console.log(url);
  const newImageRef = push(imagesRef);
  update(newImageRef, { url: url});
};

const deleteImage = (id) => {
  const imageRef = dbRef(db, `home/images/${id}`);
  remove(imageRef);
};

async function onImageFileChange(event) {
    let _file = event.target.files[0];
    if (!_file) {
        return;
    }
    const randomName = Math.random().toString(36).substring(2, 15);
    addImage(await uploadFileToFirebase(_file, "home/images", `${randomName}.jpg`));
};

async function onAdFileChange(event, adId) {
  const file = event.target.files[0];
  if (!file) {
    return;
  }
  const imageUrl = await uploadFileToFirebase(file, "home/ads", `ad-${adId}.jpg`);
  const adIndex = ads.value.findIndex(ad => ad.id === adId);
  ads.value[adIndex].imgUrl = imageUrl; // Update the imageUrl in the ads array
};

const saveAd = (adId) => {
  const adIndex = ads.value.findIndex(ad => ad.id === adId);
  const adData = ads.value[adIndex];
  const adRef = dbRef(db, `home/ads/${adId}`);
  set(adRef, {imgUrl: adData.imgUrl, adUrl: adData.adUrl });
};

function triggerFileInput(adId) {
  const fileInput = document.getElementById(`adFile-${adId}`);
  fileInput.click();
}

</script>

<style scoped>
.dashboard {
  display: flex;
}

.content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% ;
}

.card-deck {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

.card {
  flex: 0 0 auto;
  position: relative;
}

.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
  padding: 10px;
}


.file-input {
  display:none;
}

.url-input {
  margin-top: 10px;
  width: 85%;
}

.ads-section .ad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.ad-preview {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.ad-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}
.btn{
    background-color: rgb(241, 88, 39);
    border-color: rgb(241, 88, 39);
    color: rgb(0, 0, 0);
}
.btn:hover{
    background-color: rgb(232, 68, 14);
    border-color: rgb(241, 88, 39);
    color: rgb(255, 255, 255);
}

#images-scroll::-webkit-scrollbar {
    width: 2px;
    background-color: rgb(255, 255, 255);
}

#images-scroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
}

#images-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(255, 253, 253);
  }

#images-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(241, 88, 39);
    outline: 1px solid rgb(241, 88, 39);
}
</style>
