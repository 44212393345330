<template>
  <div class="articles-wrapper">
    <div class="container-fluid articles-container">
        <UserStrip v-if="isHPUser==false"/>
        <h2 class="text-center mb-4">قائمة المقالات</h2>
        <div class="row mb-4">
            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center flex-wrap">
                <button class="btn btn-primary mb-2" data-toggle="modal" data-target="#exampleModal" @click="openModal()">
                    <i class="fas fa-plus"></i> جديد
                </button>
                <SearchComponent @submit:search="startSearch" class="mb-2"/>
            </div>
        </div>
        <ArticleModal :articleToEdit="selectedArticle"/>

        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>العنوان</th>                        
                        <th v-if="isHPUser">القسم</th>
                        <th v-if="isHPUser">الكاتب</th>
                        <th>اللغة</th>
                        <th>تاريخ الإضافة</th>
                        <th>آخر تعديل</th>
                        <th>آخر تعديل بواسطة</th>
                        <th>عمليات</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="article in articles" :key="article.id">
                        <td>{{ article.title }}</td>
                        <td v-if="isHPUser">{{ article.category.nameAr }}</td>
                        <td v-if="isHPUser">{{ article.author.displayName }}</td>
                        <td>{{ article.lang == 1 ? "عربي" : "أنجليزي" }}</td>
                        <td>{{ formatDateTime(article.addedAt) }}</td>
                        <td>{{ article.lastEdit ? formatDateTime(article.lastEdit) : "لم يسبق تعديله" }}</td>
                        <td>{{ article.lastEditBy ? article.lastEditBy.displayName : "" }}</td>
                        <td>
                            <div class="btn-group" role="group">
                                <a class="btn btn-action" :href="'https://staging.yafeapedia.com/article/' + article.id" target="_blank">
                                    عرض
                                </a>
                                <button class="btn btn-action edit-button" @click="editArticle(article)" data-toggle="modal"
                                    data-target="#exampleModal">
                                    تعديل
                                </button>
                                <DeleteButtonComponent :id="article.id" @delete="deleteArticle"/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                        @page-changed="handlePageChange" />
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
  
<script setup>
import { ArticleControllerApi, ApiClient,PagesControllerApi,SearchControllerApi } from "../api/index.js";
import { ref } from 'vue'
import ArticleModal from "@/components/ArticleModal.vue";
import PaginationComponent from '@/components/PaginationComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import DeleteButtonComponent from '@/components/DeleteButtonComponent.vue';
import UserStrip from '@/components/UserStrip.vue';

const apiClient = new ApiClient();
const articleApi = new ArticleControllerApi(apiClient);
const pagesApi = new PagesControllerApi(apiClient);
const searchApi = new SearchControllerApi(apiClient);

const articles = ref([]);

const searchMode=ref(false);
const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedArticle = ref({});
const isHPUser = JSON.parse(localStorage.getItem("isHPUser") || "false");

const fetchArticles = () => {
    if(searchMode.value==false){
    if(isHPUser==true)
    articleApi.getAllArticles(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
        parseResult(data);
    });
    else{
        pagesApi.getAllPArticles(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
            parseResult(data);
        });
    }
}else{
    if(isHPUser==true)
    searchApi.searchArticles(currentPage.value,pageSize.value,query.value,1,(error,data,response)=>{
        parseResult(data);
    });
    else
    searchApi.searchPArticles(currentPage.value,pageSize.value,query.value,1,localStorage.getItem('author'),(error,data,response)=>{
        parseResult(data);
    });
}

};
function parseResult(data){
        articles.value = data.content;
        currentPage.value = data.pageable.pageNumber;
        pageSize.value = data.pageable.pageSize;
        totalElements.value = data.totalElements;
}

const editArticle = (article) => {
    selectedArticle.value = article;
}

const deleteArticle = (id) => {
    if(isHPUser==true)
    articleApi.deleteArticle(id, localStorage.getItem("token"), (error, data, response) => {
        // Handle the response
        if (data == true)
            location.reload();
    });
    else 
    pagesApi.deletePArticle(id, localStorage.getItem("token"), (error, data, response) => {
        // Handle the response
        if (data == true)
            location.reload();
    });
}

const formatDateTime = (dateObj) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ar-US', options).format(dateObj);
}

const openModal = () => {
  selectedArticle.value = null;
};

const startSearch = (_) => {
    currentPage.value=0;
    searchMode.value=true;
    query.value=_;
    fetchArticles();
};

const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchArticles();
};

fetchArticles();
</script>

<style scoped>
.articles-wrapper {
  padding: 1rem;
  min-height: 100vh;
  background-color: var(--background-light);
}

.articles-container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

h2 {
    color: var(--primary-dark);
    font-weight: bold;
    margin-bottom: 2rem;
}

.table {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
}

.table thead {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.table th, .table td {
    vertical-align: middle;
}

.table tbody tr:hover {
    background-color: var(--background-light);
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .articles-container {
        padding: 1rem;
    }

    .table-responsive {
        border: 0;
    }

    .table thead {
        display: none;
    }

    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 1rem;
        border: 1px solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.5rem;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
    }

    .btn-group {
        display: flex;
        flex-direction: row;
    }

    .btn-group .btn {
        margin-bottom: 0.5rem;
    }
}
</style>

