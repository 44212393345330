<template>
    <div class="categories-wrapper">
        <div class="container-fluid categories-container">
            <h2 class="text-center">إدارة الأقسام</h2>
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center flex-wrap">
                    <button class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="openModal()">
                        <i class="fas fa-plus"></i> جديد
                    </button>
                    <SearchComponent @submit:search="startSearch"/>
                </div>
            </div>

            <CategoryModal :category="selectedCategory" />
            <!-- :categories="categories"  -->

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>الاسم</th>
                            <th>الاسم الانجليزي</th>
                            <th>النوع</th>
                            <th>ضمن</th>
                            <th>عمليات</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in categories" :key="category.id">
                            <!-- <td>{{ category.id }}</td> -->
                            <td>{{ category.nameAr }}</td>
                            <td>{{ category.nameEn }}</td>
                            <td>{{ category.isMediaCategory ? "وسائط" : "مقالات" }}</td>
                            <td>{{ category.parent != null ? category.parent.nameAr : '' }}</td>
                            <td>
                                <div class="btn-group" role="group">
                                    <a class="btn btn-action" :href="'https://staging.yafeapedia.com/category/' + category.id" target="_blank">
                                        عرض
                                    </a>
                                    <button class="btn btn-action edit-button" @click="editCategory(category)" data-toggle="modal" data-target="#exampleModal">
                                        تعديل
                                    </button>
                                    <DeleteButtonComponent :id="category.id" @delete="deleteCategory"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-start">
                        <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                            @page-changed="handlePageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { CategoryControllerApi,SearchControllerApi, ApiClient, CategoryEntity } from "../api/index.js";
import { ref } from 'vue'
import CategoryModal from "../components/CategoryModal.vue";
import PaginationComponent from '../components/PaginationComponent.vue';
import SearchComponent from '../components/SearchComponent.vue';
import DeleteButtonComponent from '@/components/DeleteButtonComponent.vue';

const apiClient = new ApiClient();
const categoryApi = new CategoryControllerApi(apiClient);
const searchApi=new SearchControllerApi(apiClient);
const categories = ref([]);

const searchMode=ref(false);
const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedCategory = ref(null);



const fetchCategories = () => {
    if(searchMode.value==false)
    categoryApi.getCategories({ page: currentPage.value, size: pageSize.value, isChild: true }, (error, data, response) => {
        if (error)
            alert(error);
        parseResult(data);
    });
    else
    searchApi.searchCategories(currentPage.value,pageSize.value,query.value,localStorage.getItem('token'),(error,data,response)=>{
        if (error)
            alert(error);
        parseResult(data);
    });
};

function parseResult(data){
    categories.value = data.content;
    currentPage.value = data.pageable.pageNumber;
    pageSize.value = data.pageable.pageSize;
    totalElements.value = data.totalElements;
}

const editCategory = (category) => {
    selectedCategory.value = category;

}

const deleteCategory = (id) => {
    categoryApi.deleteCategory(id, localStorage.getItem("token"), (error, data, response) => {
        if (data == true)
            location.reload();
        else {
            alert("لا يمكن حذف القس نظرًا لوجود مقالات فيه");
        }
    });
}


fetchCategories();
const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchCategories();
};

const openModal = () => {
  selectedCategory.value = null;
};

const startSearch = (_) => {
    currentPage.value=0;
    searchMode.value=true;
    query.value=_;
    fetchCategories();
};

</script>


<style scoped>
.categories-wrapper {
  padding: 1rem;
  min-height: 100vh;
  background-color: var(--background-light);
}

.categories-container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

h2 {
    color: var(--primary-dark);
    font-weight: bold;
    margin-bottom: 2rem;
}

.table {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
}

.table thead {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.table th, .table td {
    vertical-align: middle;
}

.table tbody tr:hover {
    background-color: var(--background-light);
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .categories-container {
        padding: 1rem;
    }

    .table-responsive {
        border: 0;
    }

    .table thead {
        display: none;
    }

    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 1rem;
        border: 1px solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.5rem;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
    }

    .btn-group {
        display: flex;
        flex-direction: column;
    }

    .btn-group .btn {
        margin-bottom: 0.5rem;
    }
}
</style>
