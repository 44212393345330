<template>
    <div class="users-wrapper">
        <div class="container-fluid users-container" v-if="showTable">
            <h2 class="text-center">المستخدمون</h2>
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center flex-wrap">
                    <button class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="openModal()">
                        <i class="fas fa-plus"></i> جديد
                    </button>
                    <SearchComponent @submit:search="startSearch"/>
                </div>            
            </div>
            <UsersModal :userToEdit="selectedUser" />

            <!-- </a> -->
            <div class="table-responsive">
            <table class="table">
                <thead>

                    <tr>
                        <th>المستخدم</th>
                        <th>الصلاحيات</th>
                        <th>الحالة</th>
                        <th>عمليات</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <!-- <td>{{ users.id }}</td> -->
                        <td>{{ user.username }}</td>
                        <td>{{ user.privileges == 'ROLE_USER' ? "محدود" : "مشرف" }}</td>
                        <td>{{ user.enabled ? "نشط" : 'موقف' }}</td>
                        <td>
                            <button class="btn btn-action edit-button" @click="editUser(user)" data-toggle="modal" data-target="#exampleModal">
                                تعديل
                            </button>
                            <button class="btn btn-action" @click="disableUser(user.id, !user.enabled)">
                                {{ user.enabled ? "إيقاف" : "تفعيل" }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-start">
                        <PaginationComponent :total-elements="totalElements" :page-size="pageSize" :current-page="currentPage"
                            @page-changed="handlePageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { AuthenticationControllerApi,SearchControllerApi, ApiClient } from "../api/index.js";
import { ref } from 'vue'
import SearchComponent from "../components/SearchComponent.vue";
import UsersModal from "../components/UsersModal.vue";
import PaginationComponent from '../components/PaginationComponent.vue';


const apiClient = new ApiClient();
const usersApi = new AuthenticationControllerApi(apiClient);
const searchApi = new SearchControllerApi(apiClient);
const users = ref([]);


const searchMode=ref(false);
const query=ref(null);
const totalElements = ref(0);
const pageSize = ref(10);
const currentPage = ref(0);
const selectedUser = ref(null);


let showTable = true;

const fetchUsers = () => {
    if(searchMode.value==false){
    usersApi.getUsers(localStorage.getItem("token"), { page: currentPage.value, size: pageSize.value }, (error, data, response) => {
        if (error)
            alert(error);
      parseResult(data);
    });
}else{
    searchApi.searchUsersByUserName(currentPage.value,pageSize.value,query.value,localStorage.getItem('token'),(error,data,response)=>{
        if (error)
            alert(error);
      parseResult(data);
    });
}
};

function parseResult(data){
    users.value = data.content;
    currentPage.value = data.pageable.pageNumber;
    pageSize.value = data.pageable.pageSize;
    totalElements.value = data.totalElements;
}

const editUser = (user) => {
    selectedUser.value = user;

}

const disableUser = (id, newState) => {
    usersApi.changeEnableState(newState, id, localStorage.getItem("token"), (error, data, response) => {
        if (data.body.second)
            location.reload();
        else {
            alert("حصل خطأ غير معروف");
        }
    });
}
const openModal = () => {
    selectedUser.value = null;
};
const handlePageChange = (newPage) => {
    currentPage.value = newPage;
    fetchUsers();
};

const startSearch = (_) => {
    currentPage.value=0;
    searchMode.value=true;
    query.value=_;
    fetchUsers();
};

fetchUsers();
</script>

<style scoped>
.users-wrapper {
  padding: 1rem;
  min-height: 100vh;
  background-color: var(--background-light);
}

.users-container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.container-fluid {
  padding: 2rem;
  min-height: 100vh;
  background-color: white;
}

h2 {
    color: var(--primary-dark);
    font-weight: bold;
    margin-bottom: 2rem;
}

.table {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
}

.table thead {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.table th, .table td {
    vertical-align: middle;
}

@media (max-width: 768px) {
    .container-fluid {
        padding: 1rem;
    }

    .table-responsive {
        border: 0;
    }

    .table thead {
        display: none;
    }

    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 1rem;
        border: 1px solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.5rem;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
    }

    .btn-group {
        display: flex;
        flex-direction: row;
    }

    .btn-group .btn {
        margin-bottom: 0.5rem;
    }
}
</style>
