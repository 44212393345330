<template>
    <div class="password-modal-container">
        <div class="password-modal-overlay" @click="cancelChange">
            <div class="password-modal" @click.stop>
                <h3 class="text-center">تغيير كلمة المرور</h3>
                
                <div class="form-group">
                    <input 
                        :type="showPassword ? 'text' : 'password'" 
                        v-model="password"
                        placeholder="كلمة المرور الجديدة"
                        class="password-input"
                    />
                </div>
                
                <div class="form-group">
                    <input 
                        :type="showPassword ? 'text' : 'password'" 
                        v-model="confirmPassword"
                        placeholder="تأكيد كلمة المرور"
                        class="password-input"
                    />
                </div>

                <div class="form-group">
                    <input type="checkbox" id="show-password" v-model="showPassword" />
                    <label for="show-password">إظهار كلمة المرور</label>
                </div>

                <label>قوة كلمة المرور:</label>
                <div class="password-strength-bar">                   
                    <div :class="`strength-bar ${passwordStrengthClass}`">
                    </div>
                </div>

                <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

                <div class="button-group">
                    <button @click="confirmChange" class="confirm-btn">تأكيد</button>
                    <button @click="cancelChange" class="cancel-btn">إلغاء</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';

const emits = defineEmits(["update", "close"]);

const props = defineProps(["msg"]);

const password = ref('');
const confirmPassword = ref('');
const errorMessage = ref('');
const showPassword = ref(false);

watch(props.msg, (newVal) => {
    errorMessage.value = newVal;
});

const passwordStrengthClass = computed(() => {
    const lengthCriteria = password.value.length >= 8;
    const numberCriteria = /\d/.test(password.value);
    const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password.value);

    if (lengthCriteria && numberCriteria && specialCharCriteria) {
        return 'strong';
    } else if (lengthCriteria && (numberCriteria || specialCharCriteria)) {
        return 'medium';
    } else if (password.value.length == 0) {
        return '';
    } else {
        return 'weak';
    }
});

const confirmChange = () => {
    if (!password.value || !confirmPassword.value) {
        errorMessage.value = 'يرجى ملء جميع الحقول';
        return;
    }
    
    if (password.value !== confirmPassword.value) {
        errorMessage.value = 'كلمات المرور غير متطابقة';
        return;
    }
    
    emits("update", password.value);
    resetForm();
}

const cancelChange = () => {
    resetForm();
    emits("close");
}

const resetForm = () => {
    password.value = '';
    confirmPassword.value = '';
    errorMessage.value = '';
}
</script>

<style scoped>
.password-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.password-modal {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 15px;
}

.password-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    direction: rtl;
}

.error-message {
    color: #e74c3c;
    font-size: 14px;
    margin: 10px 0;
    text-align: start;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.confirm-btn, .cancel-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.confirm-btn {
    background-color: var(--primary-color);
    color: white;
}

.confirm-btn:hover {
    background-color: var(--primary-light);
}

.cancel-btn {
    background-color: #e74c3c;
    color: white;
}

.cancel-btn:hover {
    background-color: #c0392b;
}

h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
}

.password-strength-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
}

.strength-bar {
    height: 100%;
    border-radius: 5px;
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    animation: pulse 1.5s infinite;
}

.strength-bar::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0;
    transition: opacity 0.3s;
}

.strength-bar:hover::after {
    opacity: 1;
}

.strong {
    width: 100%;
    background: linear-gradient(90deg, #2e784d, #27ae60); /* Green gradient */
    box-shadow: 0 0 10px rgba(46, 120, 77, 0.5);
}

.medium {
    width: 66%;
    background: linear-gradient(90deg, #aaa078, #f39c12); /* Yellow gradient */
    box-shadow: 0 0 10px rgba(170, 160, 120, 0.5);
}

.weak {
    width: 33%;
    background: linear-gradient(90deg, #885752, #c0392b); /* Red gradient */
    box-shadow: 0 0 10px rgba(136, 87, 82, 0.5);
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
}
</style> 