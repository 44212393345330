import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Articles from '@/views/Articles.vue'
import Categories from '@/views/Categories.vue'
import Users from '@/views/Users.vue'
import Media from '@/views/Media.vue'
import Login from '@/views/Login.vue'
import HomeView from '@/views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/myArticles',
    name: 'myArticles',
    component: Articles,
    meta: { requiresAuth: true, HP: false }
  },
  {
    path: '/home',
    component: HomeView,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuth: true, HP:true }
      },
      {
        path: 'categories',
        name: 'categories',
        component: Categories,
        meta: { requiresAuth: true, HP:true }
      },
      {
        path: 'articles',
        name: 'articles',
        component: Articles,
        meta: { requiresAuth: true, HP:true }
      },
      {
        path: 'media',
        name: 'media',
        component: Media,
        meta: { requiresAuth: true, HP:true }
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
        meta: { requiresAuth: true, HP:true }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresHP = to.matched.some(record => record.meta.HP);
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isHPUser = localStorage.getItem('isHPUser') === 'true';
  const authTime = localStorage.getItem('authTime');
  const currentTime = new Date().getTime();
  const timeLimit = 3600000; // 1 hour in milliseconds

  // Check if the session has expired
  if (isAuthenticated && authTime && (currentTime - parseInt(authTime) > timeLimit)) {
    localStorage.setItem('isAuthenticated', 'false');
    next('/');
    alert('إنتهت الجلسة!');
  } 
  // Check if route requires authentication but user is not authenticated
  else if (requiresAuth && !isAuthenticated) {
    next('/');
  }
  // Check if route requires HP access but user is not HP
  else if (requiresHP && !isHPUser) {
    next('/myArticles'); // Redirect non-HP users to myArticles
  }
  // If trying to access login page while authenticated
  else if (!requiresAuth && isAuthenticated && to.name === 'login') {
    next(isHPUser ? '/home/dashboard' : '/myArticles');
  } 
  else {
    next();
  }
})

export default router
