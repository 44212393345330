<template>
  <div class="d-flex align-items-center mt-3">
    <input 
      type="text" 
      class="form-control form-control-lg rounded me-2" 
      placeholder="بحث..." 
      v-model="searchQuery" 
      @input="handleInput" 
      @keyup.enter="submitSearch"
    />
    <button class="btn bg-transparent btn-outline-transparent" @click="submitSearch">
      <i class="fa fa-search"></i>
    </button>  
  </div>
</template>

<script setup>

import { ref } from "vue";

const searchQuery = ref('');

const emit = defineEmits(['submit:search']);


const handleInput = () => {
};

const submitSearch = () => {
  if(searchQuery.value.length>2){
    emit('submit:search',searchQuery.value);
  }
};
</script>

<style scoped>
.form-control {
  border-color: var(--primary-color);
  transition: box-shadow 0.2s ease-in-out;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-color-rgb), 0.25);
}

.btn {
  color: var(--primary-color);
  transition: color 0.2s ease-in-out;
}

.btn:focus, .btn:active, .btn:hover {
  outline: none !important;
  box-shadow: none !important;
  color: var(--text-light) !important;
}
</style>
