<template>
  <div class="user-strip">
    <div class="username" @click="toggleMenu">
      <i class="fas fa-chevron-down" :class="{ 'rotate-180': isMenuOpen }"></i>
      {{ username }}
    </div>
    
    <div v-if="isMenuOpen" class="menu">
      <div class="menu-item" style="cursor: default; background-color: #f5f5f5;">
        {{ username }}
      </div>
      <div class="menu-item" @click="changePassword">
        <i class="fas fa-key"></i>
        تغيير كلمة المرور
      </div>
      <div class="menu-item" @click="logout">
        <i class="fas fa-sign-out-alt"></i>
        تسجيل الخروج
      </div>
    </div>
  </div>
  
  <ChangePasswordModal 
    v-if="showPasswordModal"
    @update="handlePasswordUpdate"
    @close="showPasswordModal = false"
    :msg="errorMessage"
  />
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import {signOut,getAuth, updatePassword} from 'firebase/auth'
import ChangePasswordModal from './ChangePasswordModal.vue'
import {AuthenticationControllerApi,ApiClient} from '@/api/index.js'

const router = useRouter()

const isMenuOpen = ref(false);
const username = ref(localStorage.getItem('displayName'));

const errorMessage = ref('');

const authApi = new AuthenticationControllerApi(new ApiClient());

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const changePassword = () => {
  showPasswordModal.value = true;
  isMenuOpen.value = false;
}

const handlePasswordUpdate = (newPassword) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    updatePassword(user, newPassword).then(() => {
      authApi.changePassword({np:newPassword},localStorage.getItem('token'),(error,data,response) => {
        // if(error){
        //   errorMessage.value = 'لم يتم تغيير كلمة المرور';
        //   return;
        // }
        showPasswordModal.value = false;
        document.location.reload();
      }).catch((error) => {
        errorMessage.value = 'لم يتم تغيير كلمة المرور';
      });
    });
  } catch (error) {
    errorMessage.value = 'لم يتم تغيير كلمة المرور';
  }
}

const logout = () => {
  signOut(getAuth());
  localStorage.clear();
  window.location.reload();
};

const handleClickOutside = (event) => {
  const userStrip = event.target.closest('.user-strip')
  if (!userStrip && isMenuOpen.value) {
    isMenuOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

const showPasswordModal = ref(false);
</script>

<style scoped>
.user-strip {
  position: relative;
  padding: 8px;
  direction: ltr;
}

.username {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.5s ease;
  background-color: #f8f9fa;
  font-weight: 500;
}

.username:hover {
  background-color: #e9ecef;
  transform: translateY(-1px);
}

.menu {
  position: absolute;
  left: 0;
  top: 45px;
  z-index: 1000;
  background: white;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  min-width: 220px;
  padding: 8px 0;
  transform-origin: top left;
  animation: menuAppear 0.9s ease;
}

@keyframes menuAppear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.menu-item {
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease;
  color: #4a5568;
}

.menu-item:hover {
  background: #f7fafc;
  color: #2d3748;
}

.menu-item i {
  font-size: 0.9em;
  width: 16px;
  text-align: center;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.fas.fa-chevron-down {
  transition: transform 0.2s ease;
}
</style>
