<template>
    <div>
        <div class="form-check form-check-inline">
            <label class="form-check-label">اللغة:</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1"
                v-model="selectedOption" @change="handleChange" />
            <label class="form-check-label" for="inlineRadio1">عربي</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2"
                v-model="selectedOption" @change="handleChange" />
            <label class="form-check-label" for="inlineRadio2">أنجليزي
            </label>
        </div>
    </div>
</template>
  
<script setup>
import {ref,watch} from "vue"
const props = defineProps(["initialValue"]);
const selectedOption = ref('1');
const emit = defineEmits(['selectedOption']);

watch(
    () => props.initialValue,
    (newInitialValue) => {
        selectedOption.value=newInitialValue;
    })
function handleChange() {
    emit('selectedOption', selectedOption);

}

</script>

<style scoped>
.form-check-inline {
  margin-right: 1rem;
}

.form-check-input {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-check-label {
  cursor: pointer;
  user-select: none;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: var(--primary-color) !important;
}
</style>
