<template>
  <div class="media-content card shadow-sm">
    <!-- Conditionally render image or audio based on media type -->
    <img v-if="mediaType === 0" :src="tempData?media.url:media.mediaUrl" alt="Media Content" class="card-img-top" style="height: 400px;"/>
    <audio v-else-if="mediaType === 1" controls :src="media.mediaUrl" class="w-100">
      ملف صوتي غير مدعوم
    </audio>
    <div class="card-body">
      <input v-model="title" placeholder="عنوان" class="form-control mb-2" @input="updateMedia"/>
      <textarea v-model="description" placeholder="وصف" class="form-control" @input="updateMedia"></textarea>
    </div>
    <button class="btn btn-danger delete-button position-absolute top-0 end-0 m-2" @click="handleDelete">
      <i class="fas fa-trash-alt"></i>
    </button>
  </div>
</template>

<script setup>
import {ref } from 'vue';

const title=ref("");
const description=ref("");

const props = defineProps({
  media: {
    type: Object,
    required: true
  },
  mediaType: {
    type: Number,
    required: true
  },
  tempData :{
    type:Boolean,
    required:true
  }
});
if(props.media.title){
  title.value=props.media.title;
}
if(props.media.description){
  description.value=props.media.description;
}

const emit = defineEmits(['deleteMedia','updateMedia']);

function handleDelete() {
  emit('deleteMedia');
}
function updateMedia(){
  emit("updateMedia",{title:title.value,description:description.value});
}
</script>

<style scoped>
.media-content {
  margin: 10px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.media-content img, .media-content audio {
  display: block;
  width: 100%;
  object-fit: cover;
}

.card-body {
  padding: 1rem;
}

.form-control {
  margin-bottom: 0.5rem;
}

.delete-button {
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.delete-button:hover {
  opacity: 1;
}

.delete-button:focus, .delete-button:active {
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}
</style>
