<template>
    <div class="d-flex flex-column align-items-center" style="margin-top: 5px;">
        <div class="custom-file mb-3 flex-column">
            <input type="file" class="custom-file-input" id="customFile" @change="onFileChange" />
            <label class="custom-file-label" for="customFile">
                {{ fileName || 'صورة' }}
            </label>
        </div>
        <img v-if="previewUrl" :src="previewUrl" alt="Preview" class="img-thumbnail" />
    </div>
</template>
  
<script setup>
import { ref, watch } from 'vue';

const props = defineProps(["url"]);
const emit = defineEmits(["save"]);

const file = ref(null);
const previewUrl = ref(null);
const fileName = ref("");

function saveImage(newVal) {
    if (newVal) {
        emit('save', newVal);
    }
}

watch(
    () => props.url,
    (url) => {
        if (url) {
            console.log(url);
            previewUrl.value = url;
        } else {
            previewUrl.value = null;
        }
    }
);

// watch(previewUrl, (newVal, oldVal) => {
//     if (newVal !== oldVal) {
//         saveImage(newVal);
//     }
// });

function onFileChange(event) {
    let _file = event.target.files[0];
    fileName.value = _file.name;
    console.log(fileName.value);
    if (!_file) {
        previewUrl.value = null;
        return;
    }
    previewUrl.value = URL.createObjectURL(_file);
    file.value = _file; // Ensure file ref is updated
    saveImage(_file);
};

</script>
  
<style scoped>
.custom-file-input {
    cursor: pointer;
}

.custom-file-label {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: ltr;
    background-color: var(--background-light);
    border-color: var(--primary-color);
    color: var(--text-dark);
}

.img-thumbnail {
    max-width: 300px;
    height: auto;
    border-color: var(--primary-color);
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: var(--primary-color) !important;
    box-shadow: none !important;
}
</style>
