<template>
  <div v-if="totalElements/pageSize > 1" class="container-fluid">
    <nav aria-label="Page navigation" class="text-center">
      <ul class="pagination justify-content-end">
        <li class="page-item" :class="{ disabled: currentPage === 0 }">
          <a class="page-link" href="#" @click.prevent="goToPage(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">السابق</span>
          </a>
        </li>
        <li class="page-item disabled"><a class="page-link" href="#">الصفحة {{ totalPages != 0 ? currentPage + 1 : 0 }} من {{
          totalPages }}</a></li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages - 1 }">
          <a class="page-link" href="#" @click.prevent="goToPage(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">التالي</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .page-link {
    font-size: 12px; /* Smaller font size on smaller screens */
  }
  .pagination {
    font-size: 14px; /* Adjust pagination font size for responsiveness */
  }
}
</style>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  totalElements: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['page-changed']);

const totalPages = computed(() => Math.ceil(props.totalElements / props.pageSize));

const goToPage = (page) => {
  if (page >= 0 && page <= totalPages.value) {
    emit('page-changed', page);
  }
};
</script>
